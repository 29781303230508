import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    $('.dialogBox').dialog({ autoOpen: false, resizable: false, width: 700 })

    $('.dialogBox .close-button').on('click', function () {
      $('.dialogBox').dialog('close')
    })

    $('#cheeseInfo').on('click', function () {
      $('#cheeseDefinitions').dialog('open')
    })
  }
}
